<template>
  <v-banner
    app
    :class="bodyClass"
    border
    color="white"
    rounded
    shaped
    small
    :single-line="isDesktop"
    :two-line="!isDesktop"
    :style="hideModal ? 'display:none' : 'display:false'"
  >
    <template v-slot:icon>
      <InfoIcon />
    </template>

    <div class="body">
      <label class="text" :class="{ 'text-truncate': isDesktop }">
        <a target="_blank" href="https://loopcv.freshdesk.com/support/solutions/articles/103000198486-consultation-program">
          {{ this.$t("shareDataBanner.support_user_banner") }}
        </a>
      </label>
      <div class="close" v-on:click="this.handleClose">
        <CloseIcon />
      </div>
    </div>
  </v-banner>
</template>

<script>
import { CloseIcon, InfoIcon } from "@/assets/fonts/icons/index";
import Cookies from "js-cookie";
import { usePostHog } from '@/composables/usePosthog';

export default {
  components: {
    CloseIcon,
    InfoIcon,
  },

  data: () => ({
    dialog: false,
    isDesktop: true,
    hideModal: false,
  }),

  computed: {
    clientName() {
      return `${this.client.firstName} ${this.client.lastName} ${this.client.email ? `(${this.client.email})` : ""}`;
    },

    primaryColor() {
      return this.$store.getters.primaryColor;
    },

    bodyClass() {
      let classStr = "banner w-100";
      if (this.hideModal) {
        classStr += " hideModal";
      } else {
        classStr += " showModal";
      }
      return classStr;
    },
  },

  mounted() {
    this.hideModal = Cookies.get("hideSupportUserBanner") || false;
  },

  methods: {
    handleResize() {
      this.isDesktop = window.screen.availWidth > 600;
    },

    handleClose() {
      this.hideModal = true;
      Cookies.set("hideSupportUserBanner", true, { secure: true, sameSite: 'Lax' });
    },
  },
    mounted(){
    const { posthog } = usePostHog();
      posthog.capture('supportuser_banner_shown', {
        banner_type: "SupportUserBanner",
        timestamp: new Date().toISOString()
      });
  }
};
</script>
<style scoped>
.banner {
  background-color: var(--grey_light) !important;
}

.body {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: inherit;
  transition: height 1s ease-in-out, opacity 1s ease-in-out;
}

.hideModal {
  opacity: 0;
  height: 0;
}
.showModal {
  opacity: 1;
  height: fit-content;
}
.text {
  cursor: default;
}

.text a {
  color: inherit;
  text-decoration: none;
}

.text a:hover {
  text-decoration: underline;
}
</style>
