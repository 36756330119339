<template>
  <div>
    <!-- Dialog content -->
    <v-dialog v-model="dialog" max-width="700">
      <v-card class="pa-6">
        <!-- Loading indicator at the top of the card -->
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="primary"
          height="4"
          class="mb-4"
        ></v-progress-linear>

        <!-- Pay new subscription -->
        <StripeCheckout 
          v-if="this.getUser.pricingPlan === 1" 
          :cost="newPlanCost" 
          :plan="newPlan" 
          :trial="false"
          @payment-success="close" 
          :disabled="loading"
        />
        <div class="upgrade-plan" v-else>
          <h3 class="mb-6">Upgrade Confirmation</h3>
          <div class="text-body-1">
            By upgrading, the difference in price between your current plan and the new plan, <br>  
            {{ costDifference }}{{currency}} will be automatically charged to your card.
          </div>
          <v-card-actions>
            <v-btn color="primary" @click="confirmChange" :loading="loading" :disabled="loading">Confirm</v-btn>
            <v-btn color="secondary" @click="close" :disabled="loading">Cancel</v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import StripeCheckout from "@/components/subscription_plan/StripeCheckout.vue";
import { mapGetters } from "vuex";
import { constant } from "@/store/constant";
import { dashboardRepo } from "@/repositories";
import EventBus from "@/plugins/eventsBus";

const plans = constant.plans;
const user = constant.user;

export default {
  components: {
    StripeCheckout,
  },

  data() {
    return {
      dialog: false,
      newPlan: -1,
      loading: false,
    };
  },

  computed: {
    ...mapGetters("user", { getUser: user.getters.getUser }),

    ...mapGetters("plans", {
      getPlans: plans.getters.GetPlans,
    }),

    pricingPlansData() {
      if (this.$store.getters.isB2C) {
        return this.getPlans?.plans;
      } else {
        return this.$store.getters.pricingPlansData;
      }
    },

    newPlanCost() {
      const plan = this.pricingPlansData.find(({ index }) => index === this.newPlan);
      return plan?.cost || 0;
    },
    
    costDifference() {
      const currentPlan = this.pricingPlansData.find(({ index }) => index === this.getUser.pricingPlan);
      const newPlan = this.pricingPlansData.find(({ index }) => index === this.newPlan);
      const difference = newPlan?.cost - currentPlan?.cost;
      return parseFloat(difference.toFixed(2));
    },
    
    currency() {
      return this.getPlans.plans[this.newPlan]?.currency || "€";
    },
  },

  methods: {
    open(newPlan) {
      this.newPlan = newPlan;
      this.dialog = true;
    },

    close() {
      if (this.loading) return; // Prevent closing while loading
      this.dialog = false;
      this.newPlan = -1;
    },
    
    async confirmChange() {
      if (this.loading) return; // Prevent double submission
      
      this.loading = true;
      try {
        const plan = this.pricingPlansData.find(({ index }) => index === this.newPlan);
        let updateResp = await dashboardRepo.upgradeSubscription(plan);
        
        if (updateResp?.success) {
      //    EventBus.emit("payment-success", this.newPlan);
          await this.$store.dispatch(user.actions.UpdateUserData, { pricingPlan: this.newPlan });
          this.$amplitude.logEvent("UPGRADE_SUBSCRIPTION_SUCCESS", {
            priceId: plan.priceId,
            plan: this.newPlan,
            promoCode: null,
          });
          this.$notify({
            message: this.$t("plans.Common.success_upgrade"),
            type: "success",
          });
          this.dialog = false;
          this.newPlan = -1;
        } else {
          this.$notify({
            message: "Upgrade failed",
            type: "error",
          });
        }
      } catch (error) {
        this.$notify({
          message: error.message || this.$t("errors.unexpected"),
          type: "danger",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.upgrade-plan {
  text-align: center;
}
</style>