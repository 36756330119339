import router from "../router";
import store from "@/store";
import { usePostHog } from '../../composables/usePosthog';
const { posthog } = usePostHog()



function userIsLoggedInThisSession() {
  const user = store.getters["user/getUser"];
  if (user && user.email && user.role) {
    return true;
  } else {
    return false;
  }
}

async function userIsLoggedIn() {
  // TODO check the error message "user is not logged in", log other errors, check the cookies directly
  if (userIsLoggedInThisSession()) {
    return true;
  }

  try {
    await store.dispatch("user/auth");
    return true;
  } catch (e) {
    return false;
  }
}

const identifyUser = () => {
  const user = store.getters["user/getUser"];
  if (user && user.email ) {
    posthog.identify(user.token, {
      email: user.email,
      role: user.role,
    });
  }
};

router.beforeEach(async (to, from, next) => {
  if (to.meta?.public) {
    if (to.meta?.redirectToAppIfAuthenticated && (await userIsLoggedIn())) {
      return next(to.query.redirect || "/");
    }
    return next();
  }
  if (await userIsLoggedIn()) {
    if (!posthog._isIdentified()) {
      identifyUser();
    }
    return next();
  }

  return store
    .dispatch("user/auth")
    .then(() => {
      if (!posthog._isIdentified()) {
      identifyUser();
      }
      next();
    })
    .catch(e => {
      const client = store.getters?.configs?.brand;
      next(`/login?redirect=${to.fullPath}`);
    });
});


