<template>
  <div class="w-100">
    <div v-if="getUser.pricingPlan===1" class="d-flex align-center justify-center mb-1 plan-duration-switch">
      <span 
        class="duration-label cursor-pointer text-primary"
        @click="planDuration = 'monthly'"
      >
      {{ $t(`plans.monthly`)}}
      </span>
      <v-switch
        v-model="planDuration"
        :true-value="'three_months'"
        :false-value="'monthly'"
        color="primary"
        hide-details
        inset
        density="comfortable"
        class="duration-switch mx-2"
      ></v-switch>
      <div class="d-flex align-center">
        <span 
          class="duration-label cursor-pointer text-primary"
          @click="planDuration = 'three_months'"
        >
        {{ $t(`plans.three_month`)}}
      </span>
        <v-chip
          color="success"
          size="small"
          class="ml-2 save-chip"
          variant="flat"
          elevation="0"
          label
        >
        {{ $t(`plans.savePercentage`)}}
        </v-chip>
      </div>
    </div>

    <div class="pricing-plans-container">
      <transition name="pricing-slide">
        <div :key="planDuration" class="pricing-plans-row">
          <div v-for="pricingPlan in displayedPricingPlans" :key="pricingPlan.index" class="pricing-plan-wrapper">
            <div class="pricing-box">
              <div class="name-area mb-3">
                <h3 class="name-text mb-3 text-h6">{{ pricingPlan.name }}</h3>
                <div v-if="getUser.pricingPlan !== pricingPlan.index" style="text-align: center">
                  <span class="price-text">{{ pricingPlan.description }}</span>
                  <span class="period-text">{{ $t("plans.perMonth") }}</span>
                  <div v-if="planDuration === 'three_months'" class="billing-info">
                    Billed {{ formatCurrency(pricingPlan.cost, pricingPlan.currency) }} every three months
                  </div>
                </div>
                <div v-else>
                  <v-chip variant="outlined" size="small" color="green" label>
                    {{ $t("plans.currentPlan") }}
                  </v-chip>
                </div>
              </div>
              <div class="image-area">
                <img :src="pricingPlan.imageUrl" :class="{ blurFlower: activePricing == pricingPlan.index }"
                  class="pricing-plan-image" width="100%" alt="Pricing Plan Illustration" />
              </div>
              <div>
                <v-btn v-if="activePricing >= pricingPlan.index || activePricing === 1" color="default" variant="outlined"
                  class="text-button pricing-select-btn"
                  :disabled="activePricing === pricingPlan.index || subscriptionIsActive || disableButtons"
                  @click="handlePlanSelection(pricingPlan.index)">
                  {{ activePricing === pricingPlan.index ? $t("plans.selected") : $t("plans.select") }}
                </v-btn>
                <div class="upgrade-button" v-else>
                  <v-btn @click="upgradeBtnClick(pricingPlan.index)" color="default" variant="outlined"
                    class="text-button pricing-select-btn">
                    {{ $t("plans.upgrade") }}
                  </v-btn>
                </div>
              </div>

              <div style="text-align: left">
                <div class="d-flex align-center description_text_block">
                  <img :src="tick" alt="tick" />
                  <p class="description-text">
                    {{ pricingPlan.maxLoops }}
                  </p>
                </div>
                <div class="d-flex align-center description_text_block">
                  <img :src="tick" alt="tick" />
                  <p class="description-text">
                    {{ pricingPlan.description2 }}
                  </p>
                </div>
                <div class="d-flex align-center description_text_block">
                  <img :src="tick" alt="tick" />
                  <label class="description-text">
                    {{ pricingPlan.jobs }}
                  </label>
                </div>
                <div class="d-flex align-center description_text_block">
                  <img :src="tick" alt="tick" />
                  <label class="description-text">
                    {{ pricingPlan.priority }}
                  </label>
                </div>
                <div class="d-flex align-center description_text_block" v-if="pricingPlan.hasOwnProperty('extension')">
                  <img :src="tick" alt="tick" />
                  <label class="description-text">
                    {{ pricingPlan.extension }}
                  </label>
                </div>

                <div class="d-flex align-center description_text_block" v-if="pricingPlan.hasOwnProperty('remote_anywhere')">
                  <img :src="tick" alt="tick" />
                  <label class="description-text">
                    {{ pricingPlan.remote_anywhere }}
                  </label>
                </div>
                <div class="d-flex align-center description_text_block" v-if="pricingPlan.hasOwnProperty('smtp')">
                  <img :src="tick" alt="tick" />
                  <label class="description-text">
                    {{ pricingPlan.smtp }}
                  </label>
                </div>
                <div class="d-flex align-center description_text_block" v-if="pricingPlan.hasOwnProperty('aiAnswers')">
                  <img :src="tick" alt="tick" />
                  <label class="description-text">
                    {{ pricingPlan.aiAnswers }}
                  </label>
                </div>
                <div class="d-flex align-center description_text_block" v-if="pricingPlan.hasOwnProperty('moreJobs')">
                  <img :src="tick" alt="tick" />
                  <label class="description-text">
                    {{ pricingPlan.moreJobs }}
                  </label>
                </div>
                <div class="d-flex align-center description_text_block" v-if="pricingPlan.hasOwnProperty('prioritySupport')">
                  <img :src="tick" alt="tick" />
                  <label class="description-text">
                    {{ pricingPlan.prioritySupport }}
                  </label>
                </div>
                <div class="d-flex align-center description_text_block" v-if="pricingPlan.hasOwnProperty('hiddenJobs')">
                  <img :src="tick" alt="tick" />
                  <label class="description-text">
                    {{ pricingPlan.hiddenJobs }}
                  </label>
                </div>
                <div class="d-flex align-center description_text_block" v-if="pricingPlan.hasOwnProperty('aiCvChecks')">
                  <img :src="tick" alt="tick" />
                  <label class="description-text">
                    {{ pricingPlan.aiCvChecks }}
                  </label>
                </div>
              </div>
              <v-spacer></v-spacer>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <v-dialog v-model="showUpgradeContactSupport" width="auto">
      <v-card>
        <v-card-text class="pa-8">
          Contact <a href="mailto:support@loopcv.com" class="email-link">support@loopcv.com</a> for a personalized experience
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" variant="text" @click="showUpgradeContactSupport = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import tick from "@/assets/images/tick-price.svg";
import xdBike from "@/assets/new-design-icons/Group 23572.svg";
import xdMotorbike from "@/assets/new-design-icons/Group 23573.svg";
import xdCar from "@/assets/new-design-icons/Group 23574.svg";
import rocket from "@/assets/new-design-icons/rocket.svg";
import { constant } from "@/store/constant";
import { toCurrency } from "@/helpers/utils/stringUtils.js";
import EventBus from "@/plugins/eventsBus";

const plans = constant.plans;
const user = constant.user;

export default {
  data() {
    return {
      tick,
      disableButtons: false,
      localActivePricing: null,
      showUpgradeContactSupport: false,
      planDuration: this.getInitialPlanDuration(),
    };
  },
  created() {
    EventBus.on('payment-success', this.onPaymentSuccess);
  },
  beforeDestroy() {
    EventBus.off('payment-success', this.onPaymentSuccess);
  },
  watch: {
    planDuration: {
      handler() {
        this.clearPromoCode();
      }
    }
  },
  computed: {
    ...mapGetters("plans", { getPlans: plans.getters.GetPlans }),
    ...mapGetters("user", { getUser: user.getters.getUser }),

    activePricing() {
      return this.localActivePricing !== null ? this.localActivePricing : this.getUser?.pricingPlan;
    },

    displayedPricingPlans() {
      if (process.env.VUE_APP_MODE !== "b2c") {
        return this.$store.getters.pricingPlansData;
      }

      const plansToUse = this.planDuration === 'monthly' 
        ? this.getPlans.plans.slice(0, 4)  
        : [this.getPlans.plans[0], ...this.getPlans.plans.slice(4, 8)]; 
      const frontendObject = [
        {
          index: 1,
          name: this.$t("plans.basic.name"),
          maxLoops: this.$t("plans.basic.maxLoops"),
          description2: this.$t("plans.basic.description2"),
          jobs: this.$t("plans.basic.job_board"),
          priority: this.$t("plans.basic.priority"),
          imageUrl: xdBike,
          icon: "looks_one",
          extension: this.$t("plans.extension"),
          aiCvChecks: this.$t("plans.basic.aiCvChecks"),
        },
        {
          index: 2,
          name: this.$t("plans.standard.name"),
          maxLoops: this.$t("plans.standard.maxLoops"),
          description2: this.$t("plans.standard.description2"),
          jobs: this.$t("plans.standard.job_board"),
          priority: this.$t("plans.standard.priority"),
          smtp: this.$t("plans.smtp"),
          remote_anywhere: this.$t("plans.remote_anywhere"),
          imageUrl: xdMotorbike,
          icon: "looks_two",
          extension: this.$t("plans.extension"),
          aiCvChecks: this.$t("plans.standard.aiCvChecks"),
        },
        {
          index: 3,
          name: this.$t("plans.premium.name"),
          maxLoops: this.$t("plans.premium.maxLoops"),
          description2: this.$t("plans.premium.description2"),
          jobs: this.$t("plans.premium.job_board"),
          priority: this.$t("plans.premium.priority"),
          smtp: this.$t("plans.smtp"),
          remote_anywhere: this.$t("plans.remote_anywhere"),
          imageUrl: xdCar,
          icon: "looks_3",
          extension: "Linkedin apply extension",
          aiAnswers: this.$t("plans.premium.aiAnswers"),
          moreJobs: this.$t("plans.premium.moreJobs"),
          prioritySupport: this.$t("plans.premium.prioritySupport"),
          hiddenJobs: this.$t("plans.premium.hiddenJobs"),
          aiCvChecks: this.$t("plans.premium.aiCvChecks"),
        },
        {
          index: 4,
          name: this.$t("plans.doneForYou.name"),
          maxLoops: this.$t("plans.doneForYou.maxLoops"),
          description2: this.$t("plans.doneForYou.description2"),
          jobs: this.$t("plans.doneForYou.job_board"),
          priority: this.$t("plans.doneForYou.priority"),
          imageUrl: rocket,
          icon: "looks_3",
          aiAnswers: this.$t("plans.doneForYou.aiAnswers"),
          moreJobs: this.$t("plans.doneForYou.moreJobs"),
          aiCvChecks: this.$t("plans.doneForYou.aiCvChecks"),
        },
      ];


      return frontendObject.map((frontendItem, index) => {
        const planData = plansToUse[index];

        const currency =  this.getPlans.currency;
        const cost = this.planDuration === 'three_months' && planData.cost !== 0 
          ? planData.cost / 3 
          : planData.cost;
        return {
          ...frontendItem,
          ...planData,
          description: `${toCurrency(cost, currency)}`,
          currency: currency,
        };
      });
    },

    subscriptionIsActive() {
      if (!this.activePricing) {
        return false;
      }
      const activePlansData = this.displayedPricingPlans.find(
        ({ index }) => index === this.activePricing
      );
      if (!activePlansData) {
        return false;
      }
      return activePlansData.cost !== 0;
    },
  },
  methods: {
    formatCurrency(amount, currency) {
      return toCurrency(amount, currency);
    },
    onPaymentSuccess(plan) {
      this.disableButtons = true;
      this.localActivePricing = plan;
    },

    clearPromoCode() {
      this.$store.commit('plans/' + plans.mutation.setPromoCode, null);
      this.$store.commit('plans/' + plans.mutation.setPromoCodeError, '');
    },

    handlePlanSelection(planIndex) {
      this.clearPromoCode();
      this.$emit('selected', planIndex);
    },

    upgradeBtnClick(pricingPlan) {
     // if (pricingPlan === 4) {
     //   this.showUpgradeContactSupport = true;
     //   return;
     // }
      this.$emit("selected", pricingPlan);
    },
    getInitialPlanDuration() {
      const urlParams = new URLSearchParams(window.location.search);
      const plan = urlParams.get('plan');
      return (plan === '5' || plan === '6') ? 'three_months' : 'monthly';
    },
  }
};
</script>

<style>
.pricing-plans-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.pricing-plans-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  width: 100%;
}

.pricing-plan-wrapper {
  flex: 1 1 auto; 
  width: calc(25% - 12px);
  min-width: 250px;
  max-width: 300px;
}

.pricing-box {
  display: flex;
  flex-direction: column;
  text-align: center;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  padding: 15px 10px;
  max-width: 400px;
  height: 100%;
}

.flex-b {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 1000px;
}

.name-area {
  min-height: 5em;
}

.image-area {
  margin: 0 auto;
  display: block;
}

.pricing-plan-image {
  margin: 0 auto;
  display: block;
  min-height: 200px;
  max-width: 160px;
}

.price-text {
  font: normal normal 600 29px/29px Manrope;
  text-align: center;
  padding-right: 5px;
}

.period-text {
  font: normal normal medium 14px/19px Manrope;
  text-align: center;
  letter-spacing: 0.14px;
  color: #989696;
}

.name-text {
  font: normal normal 600 16px/20px Manrope;
  text-align: center;
  letter-spacing: 0.16px;
  color: #35383c;
  padding-top: 5px;
}

.description_text_block {
  margin-top: 4px;
}

.description-text {
  font: normal normal medium 14px/18px Manrope;
  text-align: left;
  letter-spacing: 0.14px;
  color: #35383c;
  min-height: 50px;
  padding-left: 8px;
  margin-bottom: 0 !important;
  display: flex;
  align-items: center;
}

.dot {
  width: 9px;
  height: 9px;
  background: #2f7bc3 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 9999px;
}

.pricing-select-btn {
  position: relative;
  transition: all 0.6s ease;
  overflow: hidden;
}

.pricing-select-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg,
      transparent,
      rgba(255, 255, 255, 0.3),
      transparent);
  transition: all 0.6s ease;
}

.pricing-select-btn:hover::before {
  left: 100%;
}

.pricing-select-btn:hover {
  transform: scale(1.05);
}

.pricing-select-btn:active {
  transform: scale(0.95);
}

.contact-support {
  font-size: 16px;
  color: #333;
  text-align: center;
  margin: 10px 0;
  font-weight: bold;
}

.email-link {
  color: #007bff;
  text-decoration: none;
}

.email-link:hover {
  text-decoration: underline;
}

.monthly-comparison {
  margin-top: 4px;
  font-weight: 500;
}

.plan-duration-switch {
  flex-wrap: nowrap;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 0 16px;
  height: 48px;
}

.duration-label {
  font-size: 1.5rem;
  font-weight: 500;
  white-space: nowrap;
  padding: 0 8px;
}

.duration-switch {
  margin: 0 8px;
}

.duration-switch :deep(.v-selection-control) {
  width: 52px;
}

.billing-info {
  color: #666;
  font-size: 0.85rem;
  margin-top: 4px;
  opacity: 0.8;
}

@media (max-width: 600px) {
  .plan-duration-switch {
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 0 8px;
  }
  
  .duration-label {
    font-size: 1rem;
    padding: 0 4px;
  }

  .save-chip {
    font-size: 0.75rem;
    height: 20px !important;
    padding: 0 6px !important;
  }

  .duration-switch {
    margin: 0 4px;
  }

  .duration-switch :deep(.v-selection-control) {
    width: 44px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.duration-label {
  transition: all 0.2s ease;
  position: relative;
}

.duration-label::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--v-primary-base);
  transform: scaleX(0);
  transition: transform 0.2s ease;
}

.duration-label.text-primary::after {
  transform: scaleX(1);
}

.duration-label:hover {
  opacity: 0.9;
}

.pricing-slide-enter-active,
.pricing-slide-leave-active {
  transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  position: absolute;
  width: 100%;
}

.pricing-slide-enter-from {
  transform: translateX(100%);
}

.pricing-slide-leave-to {
  transform: translateX(-100%);
}

.pricing-slide-enter-active {
  position: relative;
}

@media screen and (max-width: 600px) {
  .pricing-plans-container {
    flex-direction: column;
    align-items: center;
  }
}
</style>
