<template>
  <v-app>
    <TheSidebar />
    <v-main>
      <v-container class="flex-xl-column">
        <FreeTrialBanner />
        <!-- <SupportUserBanner v-if="isB2C" /> -->
        <ClientAlert v-if="clientId" :clientId="clientId" />
        <span class="w-100" >
        
          <!-- <ConsentBanner /> -->
        </span>
        <slot />
      </v-container>
    </v-main>

    <slot name="app" />
  </v-app>
</template>
<script>
import TheSidebar from "./partials/TheSidebar.vue";
import ContentFooter from "./partials/ContentFooter.vue";
import ClientAlert from "./partials/ClientAlert.vue";
import FreeTrialBanner from "./partials/FreeTrialBanner.vue";
import ConsentBanner from "@/components/Banners/ConsentBanner.vue";
import SupportUserBanner from "./partials/SupportUserBanner.vue";

export default {
  name: "DefaultLayout",
  components: {
    TheSidebar,
    ContentFooter,
    ClientAlert,
    FreeTrialBanner,
    ConsentBanner,
    SupportUserBanner,
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
  computed: {
    clientId() {
      return this.$route.params.clientId;
    },
 
    isB2C() {
      return this.$store.getters.isB2C;
    },
  },
};
</script>
<style lang="scss" scoped>
.warning-banner {
  margin: 0 0 5px;
}
.container, .v-container, .v-application {
  max-width: none; // TODO good on large screens?
  background-color: #f8f8fa;
  align-content: flex-start;
}

@media screen and (max-width: 600px) {
.v-container{
  padding: unset;
}
  
  .container {
    padding-inline: 0px !important;
  }
}
</style>
