import router from "../router"; 
import store from "@/store"; 
import { constant } from "@/store/constant";  

const user = constant.user;  

async function disableJustRegistered() { 
    if (user.getters.justRegistered) { 
        await store.dispatch(user.actions.SetJustRegistered, false); 
    } 
} 

const onboardingPairs = [
    { from: '/register', to: '/overview' },
    { from: '/register', to: '/' },
    { from: '/register', to: '/profile/pricing' },
    { from: '/profile/questions', to: '/loops/create' },
    { from: '/overview', to: '/profile/info' },
    { from: '/profile/info', to: '/profile/questions' },
];
router.beforeEach(async (to, from, next) => { 
    const isOnboardingTransition = onboardingPairs.some(pair => 
        (from.path === pair.from && to.path === pair.to) 
    );

    if (!isOnboardingTransition) { 
        await disableJustRegistered(); 
    }

    next(); 
}); 
