// src/composables/usePostHog.ts
import posthog from 'posthog-js'

export function usePostHog() {
  if (process.env.VUE_APP_MODE === 'b2b') {
    return {
      posthog: {
        capture: () => {},
        identify: () => {},
        reset: () => {},
        init: () => {},
        _isIdentified: () => {}
      }
    }
  }

  posthog.init('phc_RLPGrzmq8VDaXSj2Ixai4vZey6GOQtpcRFGXSCeMvOH', {
    api_host: 'https://eu.i.posthog.com',
    autocapture: false,
    capture_pageview: true 
  })

  return {
    posthog
  }
}